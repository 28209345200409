import Landing from './pages/Landing';
import Landing3a from './pages/Landing3a';
import LandingStudents from './pages/LandingStudents';
import LandingBusinesses from './pages/LandingBusinesses';
import LandingAcademia from './pages/LandingAcademia';
import Cookies from './pages/Cookies';
import Refunds from './pages/Refunds';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import HowItWorks from "./pages/HowItWorks";
import Maintenance from './pages/Maintenance';
import NotFound from './pages/NotFound';
import BecomeANerd from './pages/BecomeANerd';
import AboutUs from './pages/AboutUs';
import HonorCode from './pages/HonorCode';
import TopNerds from './pages/TopNerds';
import ReportAbuse from './pages/ReportAbuse';
import ThankYou from "./pages/ThankYou";

import './../../themes/nerdify/assets/css/index.css';

import { IS_PROD } from '../../config';

//export const fonts = 'https://fonts.googleapis.com/css?family=Aleo:400,700|Roboto:300,300i,400,700&display=swap';
export const viewport = 'width=device-width';

export const favicons = [
  {
    type: 'image/png',
    sizes: false,
    href: '/favicon.png',
  },
];

export const routes = [
  {
    path: '/',
    meta: {
      title:
        'Nerdify | Top Study Assistants for Students, Colleges and Companies.',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'nerds help website, consultants help, research for companies, business plan help, services for colleges, college help service, help with an interview, college help, find internships, college tutoring, university tutoring, find textbooks, jobs for students, book delivery, internships for college students',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      headline: 'Need help with task overload?',
      subtitle: 'Get connected to expert Nerds!',
      hasNav: true,
      //defaultLocale: 'ca',
    },
  },
  {
    path: '/become',
    meta: {
      title: 'Nerdify | Become a Nerd',
      description:
        'Apply to become a freelance tutor. Earn money by sharing your knowledge!',
      keywords:
        'become an online tutor, become a tutor, become a nerd, online tutoring jobs, online english tutoring jobs, online college tutoring jobs, online tutoring jobs part time, how to become an online teacher, how to teach english online, online tutoring positions, freelance tutoring jobs',
      robots: 'noindex, nofollow',
    },
    component: BecomeANerd,
    pageCfg: {
    }
  },
  {
    path: '/about-us',
    meta: {
      title: 'Nerdify | About us',
      description: 'Nerdify is a platform that provides businesses, students and academia with an access to the time of high-skilled, vetted freelancers who can help with on-demand researches.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: AboutUs,
    pageCfg: {},
  },
  {
    path: '/cookies',
    meta: {
      title: 'Nerdify | Cookie Policy',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Cookies,
    pageCfg: {
    }
  },
  {
    path: '/refunds',
    meta: {
      title: 'Nerdify | Refund Policy',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Refunds,
    pageCfg: {
    }
  },
  {
    path: '/terms-and-conditions',
    meta: {
      title: 'Nerdify | Terms of Use',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Terms,
    pageCfg: {
    }
  },
  {
    path: '/privacy-policy',
    meta: {
      title: 'Nerdify | Privacy Policy',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Privacy,
    pageCfg: {
    }
  },
  {
    path: '/how-it-works',
    meta: {
      title: 'Nerdify | How it works',
      description: 'Just send us a message and our AI algorithm will pick the best available Nerd on the platform to handle your task asap!',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: HowItWorks,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/honor-code',
    meta: {
      title: 'Nerdify | Honor Code',
      description: 'Nerdify | Honor code',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: HonorCode,
    exact: true,
    pageCfg: {
    }
  },
  {
    path: '/report-abuse',
    meta: {
      title: 'Nerdify | Report abuse',
      description: 'Nerdify | Report Abuse',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: ReportAbuse,
    exact: true,
    pageCfg: {
    }
  },
  {
    path: '/top-nerds',
    meta: {
      title: 'Nerdify | Top Nerds',
      description: 'Nerdify | Top Nerds',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: TopNerds,
    exact: true,
    pageCfg: {
    }
  },

  {
    path: '/college-students-1',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'essay, essays, help with essay, essay writing tips, essay tutoring, essay writing tutoring, online essay tutor, university essay, essay assistance, best essay examples, proofread my essay, outline help, scholarship essay writing,  pay someone, do my essay, my essay, title help, essay papers',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_1',
      ...(IS_PROD && {
        defaultPhone: {
          ca: '+1 778 722 0444',
        },
      }),
    },
  },
  {
    path: '/college-students-2',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'help with paper, edit my paper, research paper, someone to edit my paper, research paper assistance, write my paper, research paper tutoring, research paper tutors, pay someone, writing research papers, college paper assistance, research help, my paper, do my paper, need help writing a paper, paper tutor, write papers, outline help, title help',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_2',
    },
  },
  {
    path: '/college-students-3',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'hw, homework, online homework, help from tutor, my homework, do my homework, homework help online, homework tutoring, homework tutor, hw help, hw tutors, online homework tutoring, Business homework, pay someone, do your homework, homework management tips, English homework, homework assistance',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_3',
    },
  },
  {
    path: '/college-students-4',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'assignment, online assignment, my assignment, online assignment assistance, help with assignments, university assignment, do my assignment, writing assignment examples, college assignment assistance, writing assignments tips, assignments help, assignment consultation',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_4',
    },
  },
  {
    path: '/college-students-5',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'writing help, online writing help, writing tips, writing assistance, writing helper, thesis writing, how to become a better writer, research writing, writing a book review,  report writing, literature review help, outline help, college writing help, college writing assistance, university writing, online writing tutor, writing tutor, need help writing, writing for students, help with college writing, master thesis writing assistance',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_5',
    },
  },
  {
    path: '/businesses',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'nerds help website, consultants help, research for companies, business plan help, services for colleges, college help service, help with an interview, college help, find internships, college tutoring, university tutoring, find textbooks, jobs for students, book delivery, internships for college students',
      robots: 'noindex, nofollow',
    },
    component: LandingBusinesses,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'businesses',
    },
  },
  {
    path: '/students',
    meta: {
      title: 'Nerdify | Top Editing Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'editing, help with editing, editing services, edit online, essay editor, paper editor, grammar editor, assignment editor, online editing, essay corrector, paper corrector, correct my essay, correct my paper, esay checker, paper checker, essay revision service, paper revision service, proofreading, help with proofreading, proofreading services, proofread online, essay proofreading, article proofreading,  paper proofreading, proofreader',
      robots: 'noindex, nofollow',
    },
    component: LandingStudents,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'students',
    },
  },
  {
    path: '/academia',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get Help With Your Task Within Minutes. Message Now.',
      keywords:
        'nerds help website, consultants help, research for companies, business plan help, services for colleges, college help service, help with an interview, college help, find internships, college tutoring, university tutoring, find textbooks, jobs for students, book delivery, internships for college students',
      robots: 'noindex, nofollow',
    },
    component: LandingAcademia,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'academia',
    },
  },
  {
    path: '/maintenance',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Maintenance,
    pageCfg: {
    }
  },

  {
    path: '/thank-you',
    meta: {
      title: 'Nerdify | #1 Personal Assistant for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'personal assistant for students, someone to edit my paper, proofread my essay, homework help from online tutor, online assignment tutoring, need help writing, concierge service for students, writing tips, nerds online writing help, help to write my paper, pay someone, title help, outline help, essay papers, essay writing tutor, my homework, homework help online, my assignment, college writing, university writing, need help writing, master thesis proofreading, paper checker, essay revision, formatting, need help writing, essay tutoring, college paper, homework tutoring, help with assignments, editing, proofreading',
      robots: 'noindex, nofollow',
    },
    component: ThankYou,
    exact: true,
    pageCfg: {
      backLink: true,
    },
  },

  {
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {
    }
  },
];
